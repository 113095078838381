.prev, .next {
    display: block;
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 60px;
    z-index: 1;
    cursor: pointer;
    transition: all .5s;
}

.prev {
    background-image: url('./../../images/icons/prewg.svg');
    right: calc(9vw + 70px);
    &:hover {
        background-image: url('./../../images/icons/prew.svg');
    }
}

.next {
    background-image: url('./../../images/icons/nextg.svg');
    right: 9vw;
    &:hover {
        background-image: url('./../../images/icons/next.svg');
    }
}

.catalog {
    padding-bottom: 100px;
    background: black;
}

.apartment {

    h2 {
        color: white;
        font-family: $secondary-font;
        font-weight: lighter;
    }

    &__slider {

        img {
            width: 100vw;
            height: 100vh;
            object-fit: cover;
        }
    }

    &_slider-wrapper {
        position: relative;
    }

    .shadow {
        display: block;
        position: absolute;
        bottom: 0;
        width: 100vw;
        height: 25vh;
        background: linear-gradient(0deg, #000000 -2.14%, rgba(0, 0, 0, 0) 90.52%);
        z-index: 2;
    }

    &__heading {
        position: absolute;
        bottom: 0;

        * {
            color: white;
            font-family: $secondary-font;
            font-weight: lighter;
            margin: 5px 0;
        }

        h1 {
            text-transform: uppercase;
        }

    }

    &__columns {
        display: flex;
        padding-bottom: 40px;
        justify-content: space-between;
        padding-top: 20px; 

        p {
            width: 47%;
            color: #fff;
        }
    }

    @include mq($until: md) {

        &__slider {
            img {
                height: 40vh;
                min-height: 250px;
            }
        }

        .prev, .next {
            bottom: -60px;
            width: 44px;
            height: 44px;
        }

        .prev {
            right: calc(5vw + 50px);
        }
        
        .next {
            right: 5vw;
        }

        &__columns {
            padding-top: 80px;
        }

    }
    


    @include mq($until: xs) {

        &__columns {
            flex-direction: column;
            p {
                width: 100%;
            }
        }

    }

} 

