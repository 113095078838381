.home-slider {

    .slick-slide img {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
    }

    @include mq($until: md) {
        .slick-slide img {
            height: 40vh;
            min-height: 250px;
        }
    }

}

.about {

    padding-top: 50px;

    p {
        text-align: center;
        line-height: 22px
    }

    &__img {
        img {
            width: 100vw;
        }
    }
    


    .horizontal-sep {
        width: 220px;
        display: block;
        margin: 18px auto;
    }

    &__offer {

        display: flex;
        margin: 30px auto 70px;

        h4 {
            font-size: rem(16px);
            color: gray;
            font-weight: 400;
        }
    }

    @include mq($until: xl) {

        h4 {
            font-size: rem(14px);
        }

        p, li {
            font-size: rem(13.5px);
        }

    }

    
    @include mq($until: md) {

        padding-top: 25px;

        &__offer{

            flex-wrap: wrap;
            margin-bottom: 0;

            &>div {
                margin-bottom: 30px;
            }
        }

        &__img {

            img {
                height: 25vh;
                object-fit: cover;
            }
        }

    }
}

.statistic {
    width: 100%;
    height: 100vh;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    &__box {
        background-color: $tr-black;
        display: flex;
        width: 1200px;
        padding: 50px 70px;
        justify-content: space-between;

        &> div {
            text-align: center;
            width: 17%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        
        span, h4 {
            color: white;
        }

        h4 {
            font-weight: 500;
        }

        span {
            font-weight: 300;
        }

        .sep {
            height: auto;
        }

        h4 {
            font-size: rem(60px);
        }

    }

    @include mq($until: sm) {


        &__box {
            padding: 40px 10px;
            flex-wrap: wrap;

            &> div {
                width: 49%;
                margin-bottom: 30px;
            }

            .sep:nth-child(4) {
                display: none;
            }

            .sep {
                margin-bottom: 30px;
            }
        }



    }
}

.project-offer {

    &:before {
        content: '';
        position: absolute;
        display: block;
        background-size: cover;
        top: 0;
        z-index: 0;
        background-image: url('./../../images/prbg2.png');
        left: -48%;
        width: 79vw;
        height: 63vw;
        opacity: .2;
    }

    position: relative;
    padding-top: 90px;
    padding-bottom: 60px;

    h2 {
        text-transform: uppercase;
        font-family: $secondary-font;
        font-weight: lighter;
    }

    &__workflow {
        width: 26vw;
        position: relative;
        margin-right: 10vw;

        &:before {
            content: '';
            background: black;
            height: 85%;
            position: absolute;
            width: 1px;
            right: 50%;
        }
    }

    svg {

        &:hover {
            .black-fill {
                fill: url(#SVGID_2_);
            }

        }
    }

    svg.black {
        &:hover {
            .black-fill {
                fill: rgb(37, 37, 37);
            }

        }
    }

    &__block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
    }

    &__icon-block {
        display: flex;
        margin-bottom: 30px;
        height: 8vw;

        &:last-of-type {
            margin-bottom: 0;
        }

        .black-fill {
            fill: black;
        }
    }

    &__text {
        display: flex;
        flex-flow: column;
        align-self: center;
        text-align: center;
        text-transform: uppercase;
        padding: 0;
        font-size: .735vw;
    }

    &__cards {
        display: flex;

        .caption {
            text-align: center;
            width: 100%;
            font-size: rem(13.5px);

            &_sub {
                text-align: center;
                width: 100%;
                font-size: rem(12px);
                font-weight: 600;
                color: gray;
                display: block;
            }
        }
    }

    &_black-container {
        background: black;
        padding: 0 25px 20px;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 35px 15px 15px;;

        ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
            li {
                padding-left: rem(16px);
                margin-top: 13px;
            }
        }
        
        h2 {
            margin-top: 20px;
            text-transform: none;
        }

        h2, span {
                text-align: center;
                color: white;
                margin-bottom: 5px;
                font-family: $secondary-font;
        }

        span {
            font-weight: 100;
        }

        .horizontal-sep {
            width: auto;
            opacity: .8;
        }
    }

    &__item {
        padding: 0;

        h3 {
            text-align: center;
            font-family: $secondary-font;
            font-size: rem(22px);
            font-weight: 600;
            margin-bottom: 0;
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        li {
            color: white;
            font-weight: 300;

            &::before {
                border: solid #fff rem(2px);
                background-color: rgb(255, 255, 255);
              }

        }

    }

    
    @include mq($until: lg) {

        &__cards {

            flex-direction: column;
            align-items: center;
        }

        &_black-container {

            min-height: 80vh;
            margin: 10px 15px;
            margin-top: 25px;
        }

        &__item {
            margin-top: 25px;
        }


    }

    @include mq($until: md) {

        &__block {
            margin-bottom: 50px;
        }

        &__icon-block {
            height: 15vw;

            p {
                font-size: 1.4vw;
            }
        }

        &__aside {
            width: 30vw;

            span {
                bottom: 15px;
            }
        }

        &__workflow {
            width: 46vw;
            position: relative;
            margin-right: 5vw;
        }

        &__text {
            font-size: 1.735vw;
        }
        
    }

    @include mq($until: xs) {

        padding-top: 35px;
        padding-bottom: 25px;

        &:after {
            width: 25vw;
            height: 18vw;
        }

        &:before {
            top: 140px;
        }

       &__block {
            flex-direction: column;
        }

        &__workflow {
            width: 70vw;
            margin: 25px auto;
        }

        &__text {
            font-size: 2.7vw;
        }
        
    }
}

.visualisation {

    background-color: black;
    padding-top: 75px;
    padding-bottom: 50px;
    margin-top: 75px;

    h2, h3, h4, p, span {
        color: white;
        font-family: $secondary-font;
        font-weight: lighter;
    }

    h2, h3 {
        text-transform: uppercase;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 25px;
    }

    p {
        max-width: 350px;
    }

    &__more {
        padding: 0 45px;
        margin-top: 25px;
        background-image: url('./../../images/icons/lence.svg');
        background-repeat: no-repeat;
        background-position-x: -10px;
        background-position-y: center;
        font-size: 1.25rem;
        background-size: 50px;
    }

    .m-t {
        margin-top: 100px;
    }

    @include mq($until: md) {

        margin-top: 0;
        padding-top: 35px;
        padding-bottom: 35px;

        p {
            display: none;
        }
    }
      
}

.turnkey {
    
    padding: 50px 0;

    &> div {
        display: flex;
        justify-content: space-between;

    }

    &__aside {

        width: 42vw;

        img {
            height: 28vw;
            object-fit: cover;
            display: block;
        }

        a {
            position: relative;
            display: block;
            transition: all .3s;

            &:hover {
                box-shadow: 0px 0px 19px 6px #00000014;
            }

            &:first-of-type img {
                margin-bottom: 25px;
            }
        }

        span {
            position: absolute;
            bottom: 0.5vw;
            right: 2vw;
            text-transform: uppercase;
        }
    }

    h2 {
        font-weight: lighter;
        font-family: $secondary-font;
    }

    svg {

        fill: black;

        .black-fill {
            fill: black;
        }

        &:hover {

            .black-fill {
                fill: url(#SVGID_2_);
            }

        }
    }

    svg.black {
        &:hover {
            .black-fill {
                fill: rgb(37, 37, 37);
            }

        }
    }

    .horizontal-sep {
        margin: 7px 0;
        padding: 1px 0;
    }

    &__workflow {
        width: 35vw;
        position: relative;
        margin-top: 50px;

        &:before {
            content: '';
            background: black;
            height: 85%;
            position: absolute;
            width: 1px;
            right: 50%;
        }
    }

    &__block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    &__icon-block {
        display: flex;
        margin-bottom: 30px;
        justify-content: space-around;
        height: 8vw;

        &:last-of-type {
            margin-bottom: 0;
        }

        .black-fill {
            fill: black;
        }

        p {
            text-transform: none;
            text-align: left;
            font-size: .79vw;
        }
    }

    &__text {
        display: flex;
        flex-flow: column;
        margin-top: 1.5vw;
        text-align: center;
        text-transform: uppercase;
        padding: 0;
        font-size: .735vw;
    }

    @include mq($until: lg) {

        &__aside {

            span {
                bottom: 20px;
            }
        }
    }

    @include mq($until: md) {

        padding: 25px 0; 

        &__workflow {
            width: 61vw;
        }

        &__text {
            font-size: 1.735vw;
        }

        &__icon-block {
            height: 15vw;

            p {
                font-size: 1.4vw;
            }
        }

        &__aside {
            width: 30vw;

            span {
                bottom: 15px;
            }
        }
    }

    @include mq($until: xs) {

        &> div {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        &__workflow {
            width: 90vw;
            margin: 25px auto;
        }

        &__text {
            font-size: 2.7vw;
        }

        &__icon-block p {
            font-size: 2vw;
        }

        &__aside {
            width: 100vw;
            img {
                height: 48vw;
                width: 100vw;
            }

            span {
                right: 26vw;
                bottom: 10px;
            }
        }
    }
}

.realisation {

    background-color: black;
    padding-top: 75px;
    padding-bottom: 150px;

    h2, h3, h4, p, span {
        color: white;
        font-family: $secondary-font;
        font-weight: lighter;
    }

    h2, h3 {
        text-transform: uppercase;
    }

    @include mq($until: md) {

        margin-top: 0;
        padding-top: 35px;
        padding-bottom: 35px;
    }

}

.contact {

    &>div {
        display: flex;
    }

    &__heading {
        justify-content: space-between;
    }

}