.header {
  display: flex;
  background: $tr-black;
  justify-content: space-between;
  align-items: center;
  min-height: 115px;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 5vh;
  transition: all .5s;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    width: 150px;
    padding-bottom: 10px
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    padding: 0 2.4vw;
    margin: 0;
    min-height: 115px;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    background-image: url('./../../images/gradient.png');
    background-size: 0;
    background-repeat: repeat-x;
    background-position-y: center;

    &:hover {
      background-size: 10px;
      .header__submenu--hidden {
        opacity: 1;
        transform: scaleY(1);
      }

      a {
        color: white;
        font-weight: 300;
      }
    }

    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }

    &::before {
      display: none;
    }

  }

  &__menuLink {
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    font-size: rem(18px);
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: $hover-color;
    }
  }

  &__lang {
    font-weight: 100;
    font-size: rem(16px);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-left: 10px;

    .sep {
      margin: 0 5px 0 7px;
      height: 14px;
    }

  }

  &__aside {
    display: flex;

    .social {
      margin-left: 10px;

      .svg-icon {
        width: 25px;
        fill: #6f6e6c;
        &:hover {
          fill: white;
        }
      }
    }
  }


  @include mq($until: md) {

    min-height: 80px;
    position: relative;
    top: 0;

    &__logo {
      width: 30vw;
      max-width: 180px;
    }

    .social {
      a {
        margin-left: 10px;
      }
    }

    &__menuItem {
      min-height: 20px;
      justify-content: center;
    }

  }
}


