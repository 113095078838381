.footer-small{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  &__realization{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span{
      transition: color .3s ease-in-out;
    }

    &:hover span{
      color: $color-main;
    }

    img{
      margin-left: 15px;
    }
  }

  a {
    color: #505050;
  }
}

.contact__member:nth-child(5) {

  .contact__member-img {
    background-position-x: 34%;
  }

}

.contact {
  background-image: url('./../../images/bg-footer.png');
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;

  &>div {
      display: flex;
  }

  &__heading {
      justify-content: space-between;

  }

  h2 {
    color: white;
    font-family: $secondary-font;
    font-weight: lighter;
    text-transform: uppercase;
  }

  &__logo{
    position: relative;
  }

  &__all-data {   

    .horizontal-sep {
      margin: 35px 0;
    }

    .svg-icon {
      width: 23px;
      fill: white;
      margin-right: 15px;
      min-width: 23px;
    }
  }

  &__aside {
    display: flex;

    .social {
      margin-left: 10px;
      display: flex;
      align-items: center;

      a:last-of-type {
        margin-left: 5px;
      }

      .svg-icon {
        width: 23px;
        fill: #6f6e6c;
        &:hover {
          fill: white;
        }
      }

    }
  }

  &__lang {
    font-weight: 100;
    font-size: rem(16px);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-left: 10px;

    .sep {
      margin: 0 5px 0 7px;
      height: 14px;
    }

  }

 p {
   color: white;
 }

  &__item {
    display: flex;
    align-items: center;
  }

  &__member-img {
    width: 9vw;
    height: 9vw;
    border-radius: 100%;
    background-position: top;
    background-size: 150%;
    margin-bottom: 25px;
    background-color: #000000;

    &_boss {
      width: 17vw;
      height: 17vw;
    }
  }

  h3, h4 {
    color: white;
    margin: 0;
  }

  h3 {
    font-weight: 800;
    margin-top: 30px;
    font-size: 1.3vw;
  }

  h4 {
    font-weight: 300;
  }

  &__team {
    width: 850px;
    margin-left: 10vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    span {
      color: white;
      font-size: .75vw;
    }

    &_text {
      width: 100%;
      text-align: center;
    }
  }

  &__member {
    display: flex;
    flex-direction: column;
    margin-bottom: 70px;
    width: 29%;
    align-items: center;

    strong {
      text-transform: uppercase;
      font-size: .75vw;
    }
  }

  @include mq($until: lg) {

    &__team {
      margin-left: 5vw;
    }
  }
  
  @include mq($until: md) {

    &__team {

      display: none;
    }

    &__member-img {

      &_boss {
        width: 200px;
        height: 200px;
      }
    }

    h3 {
      font-size: rem(19px);
    }
  }
}