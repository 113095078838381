.grid-container {
    padding-top: 50px;
    display: grid;
    gap: 1vw;
    &> div {
        background-size: cover;
        background-position: 50% 90%;

        &:hover {
                
            a {
                opacity: 1;
            }
        }

        a {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            background-color: $tr-black;
            opacity: 0;
            transition: all .5s;
            color: white;
            padding: 35px;
            padding-top: 10vw;
        }
    }

    .all { 
        background-color: #3A3A3C;

        a {
            opacity: 0.8;
            background-color: transparent;
            font-size: rem(30px);
            font-weight: 300;
            font-family: $secondary-font;
            color: white;
            align-items: center;
            justify-content: center;
            padding: initial;

            &:hover {
                background-color: black;
            }
        }
    }
    
    h2, h3, h4, p, span {
        color: white;
        font-family: $secondary-font;
        font-weight: lighter;
    }

    h2, h3 {
        text-transform: uppercase;
    }

    h4 {
        margin-top: 0;
        margin-bottom: 25px;
    }

    p {
        max-width: 350px;
    }

    
    &__more {
        padding: 0 45px;
        margin-top: 25px;
        background-image: url('./../../images/icons/lence.svg');
        background-repeat: no-repeat;
        background-position-x: -10px;
        background-position-y: center;
        font-size: 1.25rem;
        background-size: 50px;
    }
    
    &.realization {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
          "f1 f1 f2"
          "f3 f4 f4"
          "f3 f5 f6";

          .f1 { 
            grid-area: f1; 
        }
        
        .f2 { 
            grid-area: f2;
        }
        
        .f3 { 
            grid-area: f3;
            background-position: 32% 90% !important;
            span {
                margin-top: 100px;
            }
        }
        
        .f4 { 
            grid-area: f4;
            background-position: 50% 22%;
        }
        
        .f5 { 
            grid-area: f5;
        }
        
        .all { 
            grid-area: f6;
        }

    }

    &.visualization {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        grid-template-areas:
            "f1 f2 f2"
            "f3 f3 f4"
            "f5 f6 f4"
            "f7 f6 f8";

        .f1 { 
            grid-area: f1;
            background-position: 50% 70%;
        }
    
        .f2 { 
            grid-area: f2; 
        }
        
        .f3 { 
            grid-area: f3; 
        }
        
        .f4 { 
            grid-area: f4; 
            background-position: 12%;
    
            span {
                margin-top: 100px;
            }
        }
        
        .f5 { 
            grid-area: f5; 
        }
        
        .f6 { 
            grid-area: f6; 
    
            span {
                margin-top: 100px;
            }
        }
        
        .f7 { 
            grid-area: f7; 
            background-position: 12% 50%;
        }
        
        .all { 
            grid-area: f8; 
        }
    }

    @include mq($until: xl) {

        .all { 
    
            a {
                font-size: rem(25px);
            }
        }
    }

    @include mq($until: md) {

        padding-top: 25px;

        a {

            span {
                margin-top: 0!important;
                background-size: 41px;
            }
        }

        p {
            display: none;
        }

        &.realization {
            grid-template-areas: 
            "f1 f1 f2"
            "f3 f4 f4"
            "f5 f5 f6"; 
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 25vw 25vw 25vw;

        }

        &.visualization {
            grid-template-areas: 
            "f1 f2 f2"
            "f3 f3 f4"
            "f5 f6 f6"
            "f7 f7 f8"; 
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 25vw 25vw 25vw 25vw;

            
        }

        &> div a {
            padding: 15px;
        }

        .all { 
    
            a {
                font-size: rem(20px);
            }
        }

    }

    @include mq($until: xs) {

        padding-top: 10px;

        &.realization {
            grid-template-areas: 
            "f1"
            "f2"
            "f3"
            "f4"
            "f5"
            "f6"; 
            grid-template-columns: 1fr;
            grid-template-rows: 65vw 65vw 65vw 65vw 65vw 65vw ; 
            gap: 2vw;
        }

        &.visualization {
            grid-template-areas: 
            "f1"
            "f2"
            "f3"
            "f4"
            "f5"
            "f6"
            "f7"
            "f8"; 
            grid-template-columns: 1fr;
            grid-template-rows: 65vw 65vw 65vw 65vw 65vw 65vw 65vw 65vw; 
            gap: 2vw;
        }
    }
}

.apartments-list {

    &.grid-container {
        display: grid; 
        grid-template-columns: 1fr 1fr; 
        // grid-template-rows: repeat(auto-fit, 45vw 40vw);
        gap: 1vw; 
        grid-template-areas: 
            "v1 v1"
            "v2 v3"
            "v4 v4"
            "v5 v6"
            "v7 v7"  
            "v8 v9"
            "v10 v10"
            "v11 v12"
            "v13 v13"
            "v14 v15";         
            
            &> div a {
                height: 36vw;
            }
    }

    .v1 { grid-area: v1; }
    .v2 { grid-area: v2; }
    .v3 { grid-area: v3; }
    .v4 { grid-area: v4; }
    .v5 { grid-area: v5; }
    .v6 { grid-area: v6; }
    .v7 { grid-area: v7; }
    .v8 { grid-area: v8; }
    .v9 { grid-area: v9; }
    .v10 { grid-area: v10; }
    .v11 { grid-area: v11; }
    .v12 { grid-area: v12; }
    .v13 { grid-area: v13; }
    .v14 { grid-area: v14; }
    .v15 { grid-area: v15; }

    .gallery__link {
        height: 36vw;
        background-size: cover;
        background-position: center;
    }

    @include mq($until: md) {

        &.grid-container {
            gap: 2vw;

            &> div a {
                height:45vw;
            }
        }
    }

    
    @include mq($until: sm) {

        &.grid-container {

            &> div a {
                height:60vw;
            }
        }
    }
}
