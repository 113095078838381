.subsite {
    background-color: black;
    padding-top: 200px;
    padding-bottom: 100px;
    margin: 0;

    h1, h2, h3 {
        color: white;
        font-family: $secondary-font;
        font-weight: lighter;
        text-transform: uppercase;
    }

    &__content {

        // display: flex;
        // padding-bottom: 40px;
        // justify-content: space-between;
        // padding-top: 20px;

        p {
            color: white;
            // width: 47%;
        }
        
    }

    @include mq($until: md) {
        padding-top: 100px;
        padding-bottom: 70px;
    }

    @include mq($until: sm) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

}

