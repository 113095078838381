.intro {

    display: none;

    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0%;
    background-size: cover;
    z-index: 10;
    transition: all 1s;

    &__slider {
        position: absolute;
        height: 100vh;
        z-index: 8;
        width: 100vw;
        background-color: black;

        .slide {
            width: 100%;
            display: inline-block;
            height: 100vh;
            background-size: cover;  
        }
    } 


    &__button-wrap {
        display: flex;
        position: absolute;
        bottom: 14vh;
        right: 10vw;
        z-index: 10;
    }

    &__langs {
        position: absolute;
        top: 10vh;
        right: 11vw;
        z-index: 10;
    }

    &__logo {
        width: 35vw;
        position: absolute;
        top: 21vh;
        padding-bottom: 10vh;
        padding-left: 10vw;
        border-bottom: 2px solid #ffd13a99;
        z-index: 10;
    }

    &__button {
        cursor: pointer;
        margin-left: 20px;
        width: 250px;
        padding: 15px;
        font-size: rem(20px);
        display: block;
        text-transform: uppercase;
        color: white;
        border: 2px solid #8a8a8a99;
        text-align: center;
        transition: all .2s;
        background-image: linear-gradient(#00000040 75%, #00000014 100%);

        &:hover {
            color: white;
            border: 2px solid #ffd13a99;
        }
    }

    @include mq($until: sm) {
        &__langs {
            top: 20vh;
        }

        &__button-wrap {
            flex-direction: column;
            bottom: 20vh;
            right: 0;
            width: 100%;
            align-items: center;
        }

        &__button {
            margin-top: 15px;
            margin-left: 0; 
        }

        &__logo {
            width: 50vw;
            top: 23vh;
            padding-bottom: 15vh;
            padding-left: 6vw;
        }
    }

    @include mq($until: xs) {

        &__logo {
            width: 70vw;
        }
    }

    @include mq($until: md) {
        @media screen and (orientation:landscape) {
            &__button-wrap {
                right: 2vw;
                width: 100%;
                align-items: flex-end;
            }
        }
    }

};